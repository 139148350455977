import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { DiscussionEmbed } from "disqus-react"

import Layout from "../components/Layout"
import Header from "../components/Header"
import SEO from "../components/seo"
import Newsletter from "../components/Newsletter"

import "../utils/markdown.scss"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const subtitle = `Atualizado em ${
    post.frontmatter.date
  } | ${post.timeToRead.toFixed()}min para leitura | por Flavia Nunes`

  const disqusConfig = {
    shortname: "leiturasdafla",
    config: { identifier: post.fields.slug, title: post.frontmatter.title },
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title + " | Leituras por Flavia Nunes"}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="blog-post-markdown">
        <Header title={post.frontmatter.title} subtitle={subtitle} isPost={true}/>
        <Img fluid={post.frontmatter.cover.childImageSharp.fluid} />

        <div className="markdown">
        <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        <hr />
      </article>

      <nav className="blog-post-navigation">
        <ul>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev" className="button">
                « {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next" className="button">
                {next.frontmatter.title} »
              </Link>
            )}
          </li>
        </ul>
        <ul className="mobile">
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev" className="button">
                « anterior
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next" className="button">
                próximo »
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <hr/>
      <DiscussionEmbed {...disqusConfig} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD-MM-YYYY")
        description
        cover {
          childImageSharp {
            fluid(maxWidth: 820, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      timeToRead
    }
  }
`
